import { defineStore } from "pinia";
import apiClient from "../services/client";
import { db } from "../db";
export const useUsersStore = defineStore("usersStore", {
    state: () => ({
        authenticated: false,
        user: localStorage.getItem("user" || '{}'), //BUG this turns null on refresh of navigation
        users: [],
        archivedUsers: JSON.parse(localStorage.getItem("archivedUsers")) || [],
        allUsers: [],
        searchResults: [],
        usersOrg: JSON.parse(localStorage.getItem("usersOrg")) || null,
        loggedInUser: JSON.parse(localStorage.getItem("user")),
        userRoles: JSON.parse(localStorage.getItem("userRoles")) || [],
        token: localStorage.getItem("token"),
        isProxyLoggedIn: JSON.parse(localStorage.getItem("isProxyLoggedIn")) || false,
        editSelectedUser: {
            currentOrg: "Polsinelli",
            currentRole: "Subscriber",
            firstName: "Tevan",
            lastName: "Wilfred",
            emailAddress: "twilfred@polsinelli.com",
            phoneNumber: "0662319793",
        },
        selectedUsers: JSON.parse(localStorage.getItem("selectedUsers")) || [],
        editorGroupMembers:
            JSON.parse(localStorage.getItem("editorGroupMembers")) || [],
        editorGroupList:
            JSON.parse(localStorage.getItem("editorGroupList")) || [],
        showPop: false,
        popMessage: null,
        // msgBackgroundColor: "#1ec95df0",
        msgBackgroundColor: "#008000",
        isLoading: {
            users: false,
            usersOrgs: false,
            archvivedUsers: false,
            user: false,
            allUsers: false,
            searchResults: false,
        },
        isUpdated: {
            users: false,
            archivedUsers: false,
            searchResults: false,
        },
        contentUser: null,
        responseMessage: {
            type: "",
            text: "",
        },
        emailError: null,
        passWordError: null,

        //Selected Organisation
        SelectedOrganization:
            JSON.parse(localStorage.getItem("SelectedOrganization")) || null,

        hasMore: {
            users: false,
            archivedUsers: false,
            searchResults: false,
        },
        activeUsersCount: 0,
        idleUsersCount: 0,
        archivedUsersCount: 0,
    }),
    getters: {
        getUsers(state) {
            return state.users;
        },
        getActiveUsers(state) {
            return state.activeUsersCount;
        },
        getIdleUsers(state) {
            return state.idleUsersCount;
        },
        getArchivedUsers(state) {
            return state.archivedUsersCount;
        },
        getLoggedInUser(state) {
            return state.loggedInUser;
        },
        getContentUser(state) {
            return state.users.find((user) => user.id === state.contentUser);
        },
        getIsUserLoggedIn(state) {
            return state.token !== null;
        },
        getUserRoles(state) {
            return state?.userRoles;
        },
        getOrganizations() {
            // console.log('The call in getter', this.user?.user.organisation);
            return Array.isArray(this.loggedInUser?.user.organisation)
                ? [...this.loggedInUser.user.organisation]
                : [];
        },
        getSelectedOrganization: (state) => state.SelectedOrganization,
        getFilteredOrganizations(state)
        {
            return state.loggedInUser?.user.organisation.filter(org => org.id !== state.SelectedOrganization.id);
        },
        getLoggedInUserName(state) {
            return state.loggedInUser?.user.first_name + ' ' + state.loggedInUser?.user.last_name;
        },
        getLoggedInUserEmail(state) {
            return state.loggedInUser?.user.email;
        },
        isSubscriberUser(state) {
            return state.loggedInUser?.user.role_id === 3;
        },
        isAdministratorUser(state) {
            return state.loggedInUser?.user.role_id === 1;
        },
        isContentEditorUser(state) {
            return state.loggedInUser?.user.role_id === 2;
        }
    },
    actions: {
        setProxyLoggedIn(proxyLoginStatus = false) {
            this.isProxyLoggedIn = proxyLoginStatus;
            localStorage.setItem("isProxyLoggedIn", proxyLoginStatus);
        },

        async setUserToNull(val)
        {
            this.user = val;
            this.loggedInUser = val;
        },
        async setSelectedOrganizationToNull(organization) {
            this.SelectedOrganization = organization;
            // console.log('The selected organization', this.SelectedOrganization);
        },

        async setSelectedOrganization(organization) {
            this.SelectedOrganization = organization;
            localStorage.setItem(
                "SelectedOrganization",
                JSON.stringify(this.SelectedOrganization)
            );
            // console.log('The selected organization', this.SelectedOrganization);
        },

        /**
         * Fetches a user from the server.
         * @param {number} userId - The ID of the user to fetch.
         * @returns {Promise<void>} - A promise that resolves when the user is fetched successfully.
         */
        async fetchUser(userId) {
            const headers = {
                method: "POST",
                url: "/user",
                headers: {
                    "Content-Type": "application/json",
                },
                data: { user_id: userId },
            };
            try {
                await apiClient(headers).then((res) => {
                    //this.user = res.data;
                    this.loggedInUser = res.data;
                    localStorage.setItem("user", JSON.stringify(res.data));
                    // console.log('Fetched user:', this.user);
                });
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoading.users = false;
            }
        },

        /**
         * Fetches users from the server.
         *
         * @param {number} page - The page number to fetch.
         * @param {number} pageSize - The number of users per page.
         * @returns {Promise<void>} - A promise that resolves when the users are fetched.
         */
        async fetchUsers(page = 1, pageSize = 25) {
            const headers = {
                method: "GET",
                url: "/users",
                Accept: "application/json",
                params: {
                    archived: false,
                    page: page,
                    per_page: pageSize,
                }
            };

            this.isLoading.users = true;
            await apiClient(headers).then((res) => {
                if (page === 1) {
                    this.users = res.data.data;
                } else {
                    this.users = this.users.concat(res.data.data);
                }
                this.hasMore.users = page < res.data.total_pages;
                // Set the counts of active, idle, and archived users
                this.activeUsersCount = res.data.active_users_count;
                this.idleUsersCount = res.data.idle_users_count;
                this.archivedUsersCount = res.data.deleted_users_count;
            }).catch ((error) => {
                console.error(error);
            }).finally(() => {
                this.isLoading.users = false;
            });
        },
        /**
         * Fetches archived users from the server.
         *
         * @param {number} page - The page number to fetch.
         * @param {number} pageSize - The number of users per page.
         * @returns {Promise<void>} - A promise that resolves when the fetch is complete.
         */
        async fetchArchivedUsers( page = 1, pageSize = 25) {
            const headers = {
                method: "GET",
                url: "/users",
                Accept: "application/json",
                params: {
                    archived: true,
                    page: page,
                    per_page: pageSize
                }
            };

            this.isLoading.users = true;
            await apiClient(headers).then((res) => {
                if (page == 1) {
                    this.archivedUsers = res.data.data;
                } else {
                    this.archivedUsers = this.archivedUsers.concat(res.data.data);
                }
                this.hasMore.archivedUsers = page < res.data.total_pages;
                // Set the counts of active, idle, and archived users
                this.activeUsersCount = res.data.active_users_count;
                this.idleUsersCount = res.data.idle_users_count;
                this.archivedUsersCount = res.data.deleted_users_count;
            }).catch ((error) => {
                console.error(error);
            }).finally(() => {
                this.isLoading.users = false;
            });
        },

        /**
         * Fetches all users with optional filters.
         * @param {boolean} archived - Whether to include archived users.
         * @param {string} status - The status of the users to fetch.
         * @param {string} firstName - The first name of the users to fetch.
         * @param {string} organisation - The organisation of the users to fetch.
         * @returns {Promise} A promise that resolves with the response data or rejects with an error.
         */
        async fetchAllUsers(archived = false, status = "", firstName = "", organisation = "") {
            const headers = {
                method: "GET",
                url: "/users/fetch/all",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                params: {
                    archived: archived,
                    status: status,
                    first_name: firstName,
                    organisation: organisation
                }
            };
            this.isLoading.allUsers = true;
            return await apiClient(headers).then((res) => {
                this.allUsers = res.data.data;
                return res;
            }).catch ((error) => {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.data.message}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error(error);
                return error.response;
            }).finally(() => {
                this.isLoading.allUsers = false;
            });
        },

        /**
         * Searches the users list.
         *
         * @param {boolean} archived - Indicates whether to include archived users.
         * @param {number} page - The page number.
         * @param {number} pageSize - The number of users per page.
         * @param {Object} options - Additional search options.
         * @param {string} options.name - The name of the user.
         * @param {string} options.organisation - The organization of the user.
         * @returns {Promise} - A promise that resolves with the search results.
         */
        async searchUsersList( archived = false, page = 1, pageSize = 25, options = { name: '', organisation: '' }) {
            const headers = {
                method: "GET",
                url: "/users",
                Accept: "application/json",
                params: {
                    archived: archived,
                    page: page,
                    per_page: pageSize,
                    first_name: options.first_name,
                    organisation: options.organisation,
                }
            };
            this.isLoading.searchResults = true;
            await apiClient(headers).then((res) => {
                if (page === 1) {
                    this.searchResults = [];
                }
                this.searchResults = this.searchResults.concat(res.data.data);
                this.hasMore.searchResults = page < res.data.total_pages;
                return res.data;
            }).catch ((error) => {
                console.error(error);
                return error.response;
            }).finally(() => {
                this.isLoading.searchResults = false;
            });
        },

        clearSearchResults() {
            this.searchResults = [];
        },

        /**
         * Fetches all users that belong to the given organisation.
         * @param {Number} id organisation ID
         */
        async getUsersFromOrg(id) {
            let config = {
                method: "get",
                url: "/users/polsinelli/" + id,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            };
            const cachedData = await db.orgUsersList.toArray();

            if (
                cachedData && cachedData.length &&
                !this.isLoading.usersOrgs &&
                !this.isUpdated.users
            ) {
                // get users from Dexie DB if the data is cached and up to date
                this.usersOrg = JSON.parse(cachedData[0].orgsUsersListDexie);
                return;
            }
            try {
                this.isLoading.usersOrgs = true;
                this.usersOrg = await apiClient(config).then((response) => {
                    if (!cachedData || cachedData.length === 0) {
                        // add users to Dexie DB if the data is not cached
                        db.orgUsersList.add({
                            orgsUsersListDexie: JSON.stringify(response.data),
                        }, [0]);
                    } else {
                        // update users in Dexie DB if the data is cached but not up to date
                        db.orgUsersList.update(0, {
                            orgsUsersListDexie: JSON.stringify(response.data),
                        });
                    }
                    localStorage.setItem(
                        "usersOrg",
                        JSON.stringify(response.data)
                    );
                    this.isLoading.usersOrgs = false;
                    this.isUpdated.users = false;
                    return response.data;
                });
            } catch (error) {
                this.isLoading.usersOrgs = false;
                console.error(error);
            }
        },

        /**
         * API call:
         * Archives a user that has the given user id.
         * @param {Number} userId id of the user to be archived
         */
        async archiveUser(userId) {
            const data = {
                id: userId,
            };
            const reqHeaders = {
                method: "POST",
                maxBodyLength: Infinity,
                url: "/user/delete",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            // Make API call
            return await apiClient(reqHeaders)
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        /**
         * Unarchives a user with the given user ID.
         * Updates the 'isUpdated' flag for both 'users' and 'archivedUsers',
         * and fetches the updated lists of users and archived users.
         *
         * @param {number} userId - The ID of the user to be unarchived.
         * @returns {Promise<void>} - A promise that resolves once the user is unarchived.
         */
        async unarchiveUser(userId) {
            const data = {
                id: userId,
            };
            const reqHeaders = {
                method: "POST",
                maxBodyLength: Infinity,
                url: "/user/undelete",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            // Make API call
            return await apiClient(reqHeaders)
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        /**
         * Sends a POST request to the server with the updated user data and handles the response accordingly.
         * @param {Object} user user to be updated
         *  @returns {Promise<void>} - A promise that resolves once the user is updated.
         */
        async updateUser(user) {
            const data = {
                id: user?.id,
                name: user?.first_name,
                surname: user?.last_name,
                email: user?.email,
                phone: user?.phone,
                organisation_id: user?.organisation_id,
                role_id: user?.role_id,
                status: user?.status,
                birth_date: user?.birth_date,
            };

            const reqHeaders = {
                method: "POST",
                maxBodyLength: Infinity,
                url: "/user/update",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            return await apiClient(reqHeaders)
                .then((response) => {
                    this.isUpdated.users = true;
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        /**
         * Sends an invitation to a user.
         *
         * @param {object} user - The user object containing the user's ID.
         * @returns {Promise} A promise that resolves with the response from the server if the request is successful, or rejects with an error message if the request fails.
         */
        async sendInvitation(user) {
            const data = {
                id: user?.id,
            };
            const reqHeaders = {
                method: "POST",
                maxBodyLength: Infinity,
                url: "/user/invite",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            return apiClient(reqHeaders)
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    console.error(error);
                    return error;
                });
        },

        async proxyLoginUser(userId) {
            /**
             *
        if (res.status) {
            await usersStore.fetchUser();
            isLoggingIn.value = false;
            contentStore.getContent();
            email.value = "";
            password.value = "";
            if (isAdministratorUser.value) {
                router.push({ path: '/super-admin' });
            } else if (isContentEditorUser.value) {
                router.push({ path: '/content-admin' });
            } else if(isSubscriberUser.value){
                organizationList.value = usersStore.getOrganizations;
                selectOrganisationVisible.value = true;
            }
            await store.dispatch("user/getUsers", headers);
            await store.dispatch("polsinelli/getRoles", headers);
            await store.dispatch("polsinelli/getOrgans", headers);
        } else {
            LoginError.value = "Invalid Credentials!";
            isLoggingIn.value = false;
        }
    }
};
             */
            const headers = {
                method: "POST",
                url: "/users/auth-proxy",
                Accept: "application/json",
                data: { user_id: userId },
            };
            try {
                await apiClient(headers).then((res) => {
                    localStorage.setItem("token", res.token);
                    localStorage.setItem('tokenExpiration', res.expiration);
                    return res;
                });
            } catch (error) {
                console.error(error);
            }
        },
        async fetchUserRoles() {
            const cachedData = localStorage.getItem("userRoles");
            if (cachedData) {
                this.userRoles = JSON.parse(cachedData);
            } else {
                // If the data is not found in localStorage, make the API call
                try {
                    let config = {
                        method: "get",
                        url: "/role/index",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    };
                    const response = await apiClient(config);
                    this.userRoles = response.data;

                    localStorage.setItem(
                        "userRoles",
                        JSON.stringify(this.userRoles)
                    );
                } catch (error) {
                    console.error(error);
                }
            }
        },

        async addUserToSelected(user) {
            if (!this.selectedUsers.includes(user)) {
                this.selectedUsers.push(user);
                localStorage.setItem(
                    "selectedUsers",
                    JSON.stringify(this.selectedUsers)
                );
            }
        },
        async removeUserByIndexFromSelected(index) {
            this.selectedUsers.splice(index, 1);
            localStorage.setItem(
                "selectedUsers",
                JSON.stringify(this.selectedUsers)
            );
        },
        async addUserToEditorGroup(user) {
            if (!this.editorGroupMembers.includes(user)) {
                this.editorGroupMembers.push(user);
                localStorage.setItem(
                    "editorGroupMembers",
                    JSON.stringify(this.editorGroupMembers)
                );
            }
        },
        async removeUserFromEditorGroup(index) {
            this.editorGroupMembers.splice(index, 1);
            localStorage.setItem(
                "editorGroupMembers",
                JSON.stringify(this.editorGroupMembers)
            );
        },
        async CLEARSELECTEDUSERS() {
            this.selectedUsers = [];
            this.editorGroupMembers = [];
        },
        async setContentUser(id) {
            this.contentUser = id;
        },
        async clearContentUser() {
            this.contentUser = null;
        },
        clearToken() {
            this.token = null;
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
        },
        setUsersUpdated() {
            this.isUpdated.users = true;
        },

        async bulkUploadUsersFranchise(data) {
            if (!data) {
                console.error("Data is null or undefined");
                return;
            }
            const BulkUsers = data.users;
            const mappedUsers = BulkUsers.map((user) => ({
                name: user["First Name"],
                surname: user["Last Name"],
                email: user.Email,
                phone: user.Phone || "",
                role: "Subscriber",
                organisation: user.Organization,
                franchise: user.Franchise,
            }));
            const config = {
                method: "post",
                url: "/users/register/bulk/franchise",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: { users: mappedUsers },
            };
            try {
                await apiClient(config).then(() => {
                    this.isUpdated.users = true;
                    this.fetchUsers(1,  25);
                    (this.responseMessage.type = "success"),
                    (this.responseMessage.text =
                        "After users upload, an email will be sent once all users have been processed.");
                });
            } catch (error) {
                this.responseMessage.type = "error";
                this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                setTimeout(() => {
                    this.responseMessage.text = "";
                    this.responseMessage.type = "";
                }, 3000);
                console.error("Error uploading users:", error);
            }
        },

        async notificationOptIn(userId) {
            const data = {
                user_id: userId,
                subscription_type : "email",
                subscription_value: "Module",
                frequency: "Monthly",
                status: "Subscribed",
            };
            const reqHeaders = {
                method: "POST",
                maxBodyLength: Infinity,
                url: "/module/subscribe/notifications",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            return await apiClient(reqHeaders)
                .then((res) => {
                    this.isUpdated.users = true;
                    this.responseMessage.type = "success"
                    this.responseMessage.text = res.data.message;
                    this.fetchUser(userId);
                })
                .catch((error) => {
                    this.responseMessage.type = "error";
                    this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                    setTimeout(() => {
                        this.responseMessage.text = "";
                        this.responseMessage.type = "";
                    }, 3000);
                    console.error(error);
                });
        },

        async notificationOptOut(userId) {
            const data = {
                user_id: userId,
                subscription_type : "email",
                subscription_value: "Module",
                frequency: "Monthly",
                status: "Unsubscribe",
            };
            const reqHeaders = {
                method: "POST",
                maxBodyLength: Infinity,
                url: "/module/unsubscribe/notifications",
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            return await apiClient(reqHeaders)
                .then((res) => {
                    this.isUpdated.users = true;
                    this.responseMessage.type = "success"
                    this.responseMessage.text = res.data.message;
                    this.fetchUser(userId);
                })
                .catch((error) => {
                    this.responseMessage.type = "error";
                    this.responseMessage.text = `Request failed with status ${error.response.status}: ${error.response.statusText}`;
                    setTimeout(() => {
                        this.responseMessage.text = "";
                        this.responseMessage.type = "";
                    }, 3000);
                    console.error(error);
                });
        },
    },
});
