import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
// Get the current host
const currentHost = location.host;
// Checks the current host and returns the appropriate API base URL
const host =
    currentHost === "localhost"
        ? "http://localhost:8004/api"
        : currentHost === "polsinelli-web-app.azurewebsites.net" // production  URL
        ? "https://polsinelli-web-app.azurewebsites.net/api"
        : currentHost === "polsinelli-dev.azurewebsites.net" // Old dev URL
        ? "https://polsinelli-dev.azurewebsites.net/api"
        : currentHost === "polsinelli-web-posh.azurewebsites.net" // Old staging URL
        ? "https://polsinelli-web-posh.azurewebsites.net/api"
        : currentHost === "polsinelli-dev-app-v2-hvhyc5f6bvbvbvhd.eastus2-01.azurewebsites.net" // New dev URL
        ? "https://polsinelli-dev-app-v2-hvhyc5f6bvbvbvhd.eastus2-01.azurewebsites.net/api"
        : currentHost === "polsinelli-stage-app-v2-hhe0erdad7eqeaar.eastus2-01.azurewebsites.net" // New staging URL
        ? "https://polsinelli-stage-app-v2-hhe0erdad7eqeaar.eastus2-01.azurewebsites.net/api"
        : currentHost === "online.polsinelli.com" // Live app URL
        ? "https://online.polsinelli.com/api"
        : "http://localhost:8004/api"; // Default to localhost

const controller = new AbortController();
// Create an Axios instance
const apiClient = axios.create({
    baseURL: host,
    signal: controller.signal,
});

// Add a request interceptor
apiClient.interceptors.request.use(
    function (config) {
        // Get the token from localStorage
        const token = localStorage.getItem("token");
        // If the token exists, add it to the Authorization header
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }

        return config;
    },

    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor to handle API responses
apiClient.interceptors.response.use(
    // For successful responses, simply return the response
    (response) => response,
    // For errors, we implement custom logic
    async (error) => {
        const { response, config: originalRequest } = error;
        const token = localStorage.getItem("token");
        const isUnauthorized = response?.status === 401;
        const isFirstRetry = !originalRequest._retry;

        // Check if the error is due to an unauthorized request (401)
        // and it's the first retry attempt and a token exists
        if (isUnauthorized && isFirstRetry && token) {
            // Abort any ongoing requests and clear local storage
            controller.abort();
            localStorage.clear();

            // Redirect the user to the login page
            await router.push("/login");

            // Reject the promise with a custom error message
            return Promise.reject(
                new Error("Session expired. Please log in again.")
            );
        }

        // For all other types of errors, reject the promise with the original error
        return Promise.reject(error);
    }
);

export default apiClient;
